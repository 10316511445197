// import React, { useEffect } from 'react';
import { Paper, withStyles } from '@material-ui/core';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { useMemo, useState } from 'react';
import TableComponent from '../../../../common/components/Table';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import ExportToCSV from '../../common/components/ExportToCSV';
import Listings from '../Listing';

const styles = () => ({
  root: {
    width: '100%'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif !important',
    fontWeight: '600 !important',
    fontSize: '14px !important'
  },
  radioButton: {
    textAlign: 'center'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: '14px !important'
  },
  tableDateWidth: {
    width: '110px'
  },
  tableBookWidth: {
    width: '120px'
  },
  tableTitleWidth: {
    width: '120px'
  },
  tableLinkWidth: {
    width: '80px'
  },
  tableFilterTextWidth: {
    width: '150px'
  },
  tableFilterLikeWidth: {
    width: '145px'
  },
  tableCommentsWidth: {
    width: '160px'
  },
  tableUnansWidth: {
    width: '185px'
  },
  tableTrendsWidth: {
    width: '185px'
  },
  tableQuizWidth: {
    width: '170px'
  },
  tableSummaryWidth: {
    width: '180px'
  },
  tableExplainWidth: {
    width: '165px'
  },
  dateRange: {
    display: 'flex',
    padding: '10px',
    justifyContent: 'center',
    fontWeight: '600'
  },
  tableLeftScroll: {
    maxHeight: '500px',
    overflow: 'hidden',
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid #0003'
  }
});

const ChatTrendsGrid = ({
  classes,
  chatgptUtilityTrendsData,
  chatgptUtilityListingData,
  selectRadioOption,
  selectListingOption,
  startDate,
  endDate,
  sorting,
  setOrder,
  setDirectionValue,
  isUserIdExcluded
}) => {
  // const [isTrendsUnanswered, setIsTrendsUnanswered] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const startDateVal = startDate
    ? moment(startDate).format(constants.MM_DD_YYYY_FORMAT)
    : moment(new Date()).format(constants.MM_DD_YYYY_FORMAT);
  const endDateVal = endDate
    ? moment(endDate).format(constants.MM_DD_YYYY_FORMAT)
    : moment(new Date()).format(constants.MM_DD_YYYY_FORMAT);
  // useEffect(() => {
  //   if (window.location.pathname === '/chatgptutility/aistudytools/trends/unanswered') {
  //     setIsTrendsUnanswered(true);
  //   }
  // }, []);

  const aiStudyToolsAuditHeaders = [
    {
      key: 'date',
      name: 'Date Range'
    },
    {
      key: 'title',
      name: constants.TITLE
    },
    {
      key: 'bookId',
      name: constants.BOOK_ID
    },
    {
      key: 'likeCount',
      name: constants.GPT_TRENDS_LIKECOUNT
    },
    {
      key: 'disLikeCount',
      name: constants.GPT_TRENDS_DISLIKECOUNT
    },
    {
      key: 'explainCount',
      name: constants.GPT_TRENDS_EXPLAINCOUNT
    },
    {
      key: 'summaryCount',
      name: constants.GPT_TRENDS_SUMMARYCOUNT
    },
    {
      key: 'quizMcqCount',
      name: constants.GPT_TRENDS_QUIZCOUNT
    },
    {
      key: 'unAnsweredNoCSGDocCount',
      name: constants.GPT_TRENDS_UNANSWERED_NO_CSG_DOCS_COUNT
    },
    {
      key: 'unAnsweredUnknownIntentCount',
      name: constants.GPT_TRENDS_UNANSWERED_UNKNOWN_INTENT_COUNT
    },
    {
      key: 'unAnsweredNoTopicFoundCount',
      name: constants.GPT_TRENDS_UNANSWERED_NO_TOPIC_FOUND_COUNT
    },
    {
      key: 'blockWordsCount',
      name: constants.GPT_TRENDS_BLOCKED_COUNT
    },
    {
      key: 'blockWordsCount',
      name: 'Unanswered (problem solve)'
    }
  ];

  const bookWiseData = selectRadioOption
    ? chatgptUtilityTrendsData.reduce((acc, item) => {
        if (!acc[item.bookId]) {
          // If not, create an entry for it
          acc[item.bookId] = {
            date: `${startDateVal} to ${endDateVal}`,
            title: item.title,
            bookId: item.bookId,
            likeCount: 0,
            disLikeCount: 0,
            explainCount: 0,
            summaryCount: 0,
            quizMcqCount: 0,
            unknownCount: 0,
            blockWordsCount: 0,
            unAnsweredCount: 0,
            unAnsweredUnknownIntentCount: 0,
            unAnsweredNoCSGDocCount: 0,
            unAnsweredNoTopicFoundCount: 0,
            unAnsweredProblemSolveCount: 0
          };
        }

        acc[item.bookId].likeCount += item.likeCount;
        acc[item.bookId].disLikeCount += item.disLikeCount;
        acc[item.bookId].explainCount += item.explainCount;
        acc[item.bookId].summaryCount += item.summaryCount;
        acc[item.bookId].quizMcqCount += item.quizMcqCount;
        acc[item.bookId].unknownCount += item.unknownCount;
        acc[item.bookId].blockWordsCount += item.blockWordsCount;
        acc[item.bookId].unAnsweredCount += item.unAnsweredCount;
        acc[item.bookId].unAnsweredUnknownIntentCount += item.unAnsweredUnknownIntentCount;
        acc[item.bookId].unAnsweredNoCSGDocCount += item.unAnsweredNoCSGDocCount;
        acc[item.bookId].unAnsweredNoTopicFoundCount += item.unAnsweredNoTopicFoundCount;
        acc[item.bookId].unAnsweredProblemSolveCount += item.unAnsweredProblemSolveCount;

        return acc;
      }, {})
    : {};

  // Convert the accumulator object back to an array
  const aggregatedChatgptUtilityTrendsData = selectRadioOption ? Object.values(bookWiseData) : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFromToValueForPagination = (from, to, count) => {
    return `${from}-${to} of ${count}`;
  };

  const onColumnClick = (val) => {
    sorting(val);
  };

  return (
    <>
      {selectRadioOption && (
        <>
          <ExportToCSV
            headers={aiStudyToolsAuditHeaders}
            data={aggregatedChatgptUtilityTrendsData}
            fileTitle="AIStudyTrends"
          />
          <Paper sx={{ p: 2 }} className={classes.tableLeftScroll}>
            <div className={classes.dateRange}>
              Date Range: {startDateVal} - {endDateVal}
            </div>
            <TableComponent
              columns={ColumnConfig.AIStudyTrendsDataGridColumn}
              data={
                aggregatedChatgptUtilityTrendsData &&
                Array.isArray(aggregatedChatgptUtilityTrendsData) &&
                aggregatedChatgptUtilityTrendsData.length > 0 &&
                aggregatedChatgptUtilityTrendsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              }
              onColumnClick={onColumnClick}
              order={setOrder}
              onRowClick={() => {}}
              directionValue={setDirectionValue}
              userEmailDetails={{}}
              onMouseEnter={() => {}}
              isFromChannelsTrends
            />
            <TablePagination
              component="div"
              count={aggregatedChatgptUtilityTrendsData.length}
              labelDisplayedRows={({ from, to, count }) =>
                useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{ display: 'flex', justifyContent: 'center' }}
            />
          </Paper>
        </>
      )}
      {selectListingOption && (
        <Listings chatgptUtilityListingData={chatgptUtilityListingData} isUserIdExcluded={isUserIdExcluded} />
      )}
    </>
  );
};

ChatTrendsGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  chatgptUtilityTrendsData: PropTypes.array.isRequired,
  chatgptUtilityListingData: PropTypes.array.isRequired,
  selectRadioOption: PropTypes.string.isRequired,
  selectListingOption: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  sorting: PropTypes.func.isRequired,
  setDirectionValue: PropTypes.string.isRequired,
  setOrder: PropTypes.string.isRequired,
  isUserIdExcluded: PropTypes.bool.isRequired
};

export default withStyles(styles)(ChatTrendsGrid);
