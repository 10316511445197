import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const CDNToken = types
  .model('CDNToken', {
    name: types.maybeNull(types.string),
    value: types.maybeNull(types.string)
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CDN_TOKEN_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_CDN_TOKEN_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
    }
  }));

export default CDNToken;
