import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const chartsData = types.model('chartsData', {
  bookId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  usercommand: types.maybeNull(types.string),
  response: types.maybeNull(types.string),
  violenceCategory: types.maybeNull(types.string),
  violenceSeverity: types.maybeNull(types.number),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  hateCategory: types.maybeNull(types.string),
  hateSeverity: types.maybeNull(types.number),
  selfharmCategory: types.maybeNull(types.string),
  selfharmSeverity: types.maybeNull(types.number),
  sexualCategory: types.maybeNull(types.string),
  sexualSeverity: types.maybeNull(types.number)
});

const AIContentFilterChartsDetailsModel = types
  .model('AIContentFilterChartsDetailsModel', {
    status: types.maybeNull(types.string),
    data: types.optional(types.array(chartsData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_AI_CONTENT_FILTER_CHARTS_DETAILS_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_AI_CONTENT_FILTER_CHARTS_DETAILS_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_AI_CONTENT_FILTER_CHARTS_DETAILS_DATA);
    }
  }));

export default AIContentFilterChartsDetailsModel;
