import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const chartsData = types.model('chartsData', {
  type: types.maybeNull(types.string),
  dislikeCount: types.maybeNull(types.number),
  likeCount: types.maybeNull(types.number)
});

const AIChartsUtilityModel = types
  .model('AIChartsUtilityModel', {
    status: types.maybeNull(types.string),
    data: types.optional(types.array(chartsData), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_AI_CHARTS_UTILITY_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_AI_CHARTS_UTILITY_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
      Framework.getEventManager().publish(constants.SET_AI_CHARTS_UTILITY_DATA);
    }
  }));

export default AIChartsUtilityModel;
