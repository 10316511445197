import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const roleObj = types.model('roleObj', {
  prompt: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
  safety: types.maybeNull(types.string),
  grounding: types.maybeNull(types.string),
  userTopic: types.maybeNull(types.string)
});

const promptsObj = types.model('promptsObj', {
  name: types.maybeNull(types.string),
  version: types.maybeNull(types.string),
  status: types.maybeNull(types.string)
});

const quizObj = types.model('quizObj', {
  optionsList: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string)
});

// const feedbackObj = types.model('feedbackObj', {
//   answer: types.maybeNull(types.string),
//   options: types.optional(types.array(types.string), []),
//   question: types.maybeNull(types.string)
// });

const botResponseArray = types.model('botResponseArray', {
  botSummary: types.optional(types.array(types.string), []),
  processedSummary: types.optional(types.array(types.string), [])
});

const SummaryResponse = types.model('SummaryResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  botResponse: types.maybeNull(types.optional(botResponseArray, {})),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.optional(types.array(types.string), [])),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
  // feedbackContent: types.maybeNull(types.optional(types.array(types.string), []))
});

const SummaryTopic = types.model('SummaryTopic', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const mcqDistract = types.model('mcqDistract', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const DiscussResponse = types.model('DiscussResponse', {
  bookId: types.maybeNull(types.string),
  botResponse: types.optional(types.array(types.string), []),
  question: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), []),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
  // reasonForDisLike: types.optional(types.array(types.string), [])
  // feedbackContent: types.maybeNull(types.optional(types.array(types.string), []))
});

const QuizResponse = types.model('QuizResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.union(types.array(roleObj), types.string)),
  botResponse: types.maybeNull(types.union(types.array(types.optional(quizObj, {})), types.array(types.string))),
  // botResponse: types.maybeNull(types.array(types.optional(quizObj, {}))),
  // feedbackContent: types.maybeNull(types.array(types.optional(feedbackObj, {}))),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  comments: types.maybeNull(types.string),
  feedback: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), []),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
  // reasonForDisLike: types.optional(types.array(types.string), [])
});

const ChatEnrichmentResponse = types.model('ChatEnrichmentResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const ContentClassifierResponse = types.model('ContentClassifierResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const ChatIntentResponse = types.model('ChatIntentResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const FlashcardBotResponse = types.model('FlashcardBotResponse', {
  answer: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string)
});

const FlashcardsResponse = types.model('FlashcardsResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.optional(FlashcardBotResponse, {}))),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const TopicResponse = types.model('TopicResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const SummaryEnrichmentResponse = types.model('SummaryEnrichmentResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const QuizValidateResponse = types.model('QuizValidateResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), []),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const QuizIntentResponse = types.model('QuizIntentResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.optional(types.array(types.maybeNull(types.string)), []),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const McqResponse = types.model('McqResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const DictionarySummary = types.model('DictionarySummary', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const DictionaryQuiz = types.model('DictionaryQuiz', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const McqMisconceptionsResponse = types.model('McqMisconceptionsResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const McqSkillIdentifierResponse = types.model('McqSkillIdentifierResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const McqQuestionGenerationResponse = types.model('McqQuestionGenerationResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const QuizMcqResponse = types.model('QuizMcqResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.optional(types.array(types.string), []),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const QuizFreeResponse = types.model('QuizFreeResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.array(types.string), []),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const FreeResponse = types.model('FreeResponse', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.array(types.string), []),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const contentfilterResponse = types.model('contentfilterResponse', {
  Category: types.maybeNull(types.string),
  Severity: types.maybeNull(types.string)
});

const blockListResult = types.model('blockListResult', {
  blocklistName: types.maybeNull(types.string),
  blockItemText: types.maybeNull(types.string)
});

const contentFilterAllValues = types.model('contentFilterAllValues', {
  blocklistsMatchResults: types.maybeNull(types.optional(types.array(blockListResult), [])),
  Category_Severity: types.maybeNull(types.optional(types.array(contentfilterResponse), []))
});

const contentFilter = types.model('contentFilter', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.string),
  botResponse: types.maybeNull(types.optional(contentFilterAllValues, {})),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  finalStatus: types.maybeNull(types.string),
  finalResponse: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const typeString = types.model('typeString', {
  S: types.maybeNull(types.string)
});

const lastEvaluvatedKey = types.model('lastEvaluvatedKey', {
  bookId: types.maybeNull(typeString),
  serverDateTime: types.maybeNull(typeString),
  type: types.maybeNull(typeString),
  feedbackId: types.maybeNull(typeString),
  userId: types.maybeNull(typeString)
});

const ExclusiveStartKey = types.model('ExclusiveStartKey', {
  ExclusiveStartKey: types.optional(lastEvaluvatedKey, {})
});

const chatIntentBotResponse = types.model('chatIntentBotResponse', {
  format: types.maybeNull(types.string),
  intent: types.maybeNull(types.string),
  location: types.maybeNull(types.string),
  source_type: types.maybeNull(types.string),
  topic: types.maybeNull(types.string)
});

const flashcardsSubarray = types.model('flashcardsSubarray', {
  type: types.maybeNull(types.string),
  front_side: types.maybeNull(types.string),
  back_side: types.maybeNull(types.string),
  distractors: types.maybeNull(types.array(types.string))
});

const expertDeskMcqBot = types.model('expertDeskMcqBot', {
  flashcards: types.maybeNull(types.array(flashcardsSubarray))
});

const contentIdentityObj = types.model('contentIdentityObj', {
  content: types.maybeNull(types.string),
  role: types.maybeNull(types.string)
});

const pythonCodeObj = types.model('contentIdentityObj', {
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const mcqQuestionArray = types.model('mcqQuestionArray', {
  id: types.maybeNull(types.string),
  flag_status: types.maybeNull(types.string),
  explanation: types.maybeNull(types.string)
});

const mcqValidationResponse = types.model('mcqValidationResponse', {
  questions: types.maybeNull(types.array(mcqQuestionArray))
});

const botResponseAllValues = types.model('botResponseAllValues', {
  blocklistsMatchResults: types.maybeNull(types.optional(types.array(blockListResult), [])),
  Category_Severity: types.maybeNull(types.optional(types.array(contentfilterResponse), [])),
  chatIntentResponse: types.maybeNull(types.array(chatIntentBotResponse)),
  explainStandAloneBotResponse: types.maybeNull(types.string),
  salutationBotResponse: types.maybeNull(types.string),
  summaryExplainBotResponse: types.maybeNull(types.string),
  answerCyuMcqParserBotResponse: types.maybeNull(types.string),
  expertDeskMcqBotResponse: types.maybeNull(types.optional(expertDeskMcqBot, {})),
  mathMcqResponse: types.maybeNull(types.array(types.string)),
  nonMathMcqValidationResponse: types.maybeNull(types.array(types.union(mcqValidationResponse, types.string))),
  mathMcqCoreConceptResponse: types.maybeNull(types.array(types.string)),
  mathMcqQuestionGenerationResponse: types.maybeNull(types.array(types.string)),
  summarygpt4oResponse: types.maybeNull(types.array(types.string)),
  summaryGenResponse: types.maybeNull(types.array(types.string)),
  physicsMcqResponse: types.maybeNull(types.array(types.string)),
  physicsMcqQuestionGenerationResponse: types.maybeNull(types.array(types.string)),
  generatePythonResponse: types.maybeNull(types.optional(types.array(pythonCodeObj), [])),
  problemSolveFinalResponse: types.maybeNull(types.array(types.string)),
  historyTitle: types.maybeNull(types.array(types.string)),
  channelFetch: types.maybeNull(types.array(types.string)),
  quizTopicPractice1Response: types.maybeNull(types.array(types.string)),
  quizTopicPractice2Response: types.maybeNull(types.array(types.string)),
  recommendationsResponse: types.maybeNull(types.array(types.string)),
  mathMcqValidationResponse: types.maybeNull(types.array(types.union(mcqValidationResponse, types.string))),
  physicsMcqValidationResponse: types.maybeNull(types.array(types.union(mcqValidationResponse, types.string))),
  searchoverviewResponse: types.maybeNull(types.array(types.string))
});

const commonTypeModel = types.model('SubType', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.union(types.array(roleObj), types.string)),
  botResponse: types.maybeNull(types.union(botResponseAllValues, types.array(types.string))),
  question: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  finalStatus: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {}))),
  heading: types.maybeNull(types.string),
  chunkIndex: types.maybeNull(types.number),
  headingIndex: types.maybeNull(types.number)
});

const contentIdentifierUserSelection = types.model('contentIdentifierUserSelection', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.optional(contentIdentityObj, {})),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const problemSolve = types.model('problemSolve', {
  bookId: types.maybeNull(types.string),
  prompt: types.maybeNull(types.array(types.optional(roleObj, {}))),
  botResponse: types.maybeNull(types.string),
  question: types.maybeNull(types.string),
  serverDateTime: types.maybeNull(types.string),
  requestId: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  prompts: types.maybeNull(types.array(types.optional(promptsObj, {})))
});

const Response = types
  .model('Response', {
    summary: types.optional(types.array(SummaryResponse), []),
    discuss: types.optional(types.array(DiscussResponse), []),
    quiz: types.optional(types.array(QuizResponse), []),
    chat_enrichment: types.optional(types.array(ChatEnrichmentResponse), []),
    chat_intent: types.optional(types.array(ChatIntentResponse), []),
    content_classifier: types.optional(types.array(ContentClassifierResponse), []),
    flashcards: types.optional(types.array(FlashcardsResponse), []),
    topic: types.optional(types.array(TopicResponse), []),
    summary_enrichment: types.optional(types.array(SummaryEnrichmentResponse), []),
    summaryTopic: types.optional(types.array(SummaryTopic), []),
    quizValidateIntent: types.optional(types.array(QuizValidateResponse), []),
    quizIntent: types.optional(types.array(QuizIntentResponse), []),
    mcq: types.optional(types.array(McqResponse), []),
    mcqMisconceptions: types.optional(types.array(McqMisconceptionsResponse), []),
    mcqSkillIdentifier: types.optional(types.array(McqSkillIdentifierResponse), []),
    mcqQuestionGeneration: types.optional(types.array(McqQuestionGenerationResponse), []),
    quizMcq: types.optional(types.array(QuizMcqResponse), []),
    quizFreeResponse: types.optional(types.array(QuizFreeResponse), []),
    freeResponse: types.optional(types.array(FreeResponse), []),
    mcqDistract: types.optional(types.array(mcqDistract), []),
    dictionarySummary: types.optional(types.array(DictionarySummary), []),
    dictionaryQuiz: types.optional(types.array(DictionaryQuiz), []),
    content_filter: types.optional(types.array(contentFilter), []),
    explainStandalone: types.optional(types.array(commonTypeModel), []),
    salutation: types.optional(types.array(commonTypeModel), []),
    contentIdentifierUserSelection: types.optional(types.array(contentIdentifierUserSelection), []),
    chat_intent_problem_solve: types.optional(types.array(ChatIntentResponse), []),
    problemSolve: types.optional(types.array(problemSolve), []),
    summaryExplain: types.optional(types.array(commonTypeModel), []),
    answerCyuMcq: types.optional(types.array(commonTypeModel), []),
    problemSolveRectifier: types.optional(types.array(problemSolve), []),
    problemSolveVerifier: types.optional(types.array(problemSolve), []),
    isNextPageKey: types.optional(ExclusiveStartKey, {}),
    expertDeskFreeResponse: types.optional(types.array(commonTypeModel), []),
    expertDeskMcq: types.optional(types.array(commonTypeModel), []),
    commonAuditData: types.optional(types.array(commonTypeModel), []),
    commonIntentIdentifierData: types.optional(types.array(contentIdentifierUserSelection), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_CHATGPT_RESPONSE_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response.data);
      Framework.getEventManager().publish(constants.GET_CHATGPT_RESPONSE_MODEL_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.discuss, initialState);
      applySnapshot(self.quiz, initialState);
      applySnapshot(self.summary, initialState);
      applySnapshot(self.chat_enrichment, initialState);
      applySnapshot(self.chat_intent, initialState);
      applySnapshot(self.content_classifier, initialState);
      applySnapshot(self.flashcards, initialState);
      applySnapshot(self.topic, initialState);
      applySnapshot(self.summary_enrichment, initialState);
      applySnapshot(self.summaryTopic, initialState);
      applySnapshot(self.quizValidateIntent, initialState);
      applySnapshot(self.quizIntent, initialState);
      applySnapshot(self.mcq, initialState);
      applySnapshot(self.mcqMisconceptions, initialState);
      applySnapshot(self.mcqSkillIdentifier, initialState);
      applySnapshot(self.mcqQuestionGeneration, initialState);
      applySnapshot(self.quizMcq, initialState);
      applySnapshot(self.quizFreeResponse, initialState);
      applySnapshot(self.freeResponse, initialState);
      applySnapshot(self.mcqDistract, initialState);
      applySnapshot(self.dictionarySummary, initialState);
      applySnapshot(self.dictionaryQuiz, initialState);
      applySnapshot(self.explainStandalone, initialState);
      applySnapshot(self.salutation, initialState);
      applySnapshot(self.contentIdentifierUserSelection, initialState);
      applySnapshot(self.chat_intent_problem_solve, initialState);
      applySnapshot(self.summaryExplain, initialState);
      applySnapshot(self.problemSolve, initialState);
      applySnapshot(self.problemSolveRectifier, initialState);
      applySnapshot(self.problemSolveVerifier, initialState);
      applySnapshot(self.expertDeskFreeResponse, initialState);
      applySnapshot(self.expertDeskMcq, initialState);
      applySnapshot(self.commonIntentIdentifierData, initialState);
      Framework.getEventManager().publish(constants.GET_CHATGPT_RESPONSE_MODEL_DATA);
    }
  }));

export default Response;
