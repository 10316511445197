import Typography from '@material-ui/core/Typography/Typography';
import Box from '@mui/material/Box';
import { toJS } from 'mobx';
import { PropTypes } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
import Framework, { shapes } from '@greenville/framework';
import { Grid, Paper, TableSortLabel, withStyles } from '@material-ui/core';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import Listing from '../models/Listing';
// import Framework, { shapes } from '@greenville/framework';
import ChatgptUtilityIESUser from '../models/ChatgptIESUser';
import ChatDialogComponent from './datagrid/ChatDialogComponent';
// import ResponseModel from '../models/Response';
// import utils from '../../../common/utils';
// import Loader from '../../../common/Loader';
import ChatgptConfig from '../../../common/config/ChatgptConfig';
import * as constants from '../../../common/constants';
import utils from '../../../common/utils';
import InternalUsersList from '../models/InternalUsersList';
import ResponseModel from '../models/Response';

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [createData(90, 159), createData(90, 159), createData(90, 159)];

const styles = () => ({
  tableColumnHeight: {
    maxHeight: '600px',
    overflow: 'hidden',
    // overflowY: 'scroll',
    // borderRadius: '2px',
    // border: '1px solid #0003',
    marginBottom: '2%'
  },
  highlighted: {
    backgroundColor: '#8080802e'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600',
    textAlign: 'center'
  },
  tableLeftWidth: {
    paddingLeft: '12px'
  },
  tableLeftScroll: {
    maxHeight: '600px',
    overflow: 'hidden',
    overflowY: 'scroll',
    borderRadius: '2px',
    border: '1px solid #0003'
  },
  columnData: {
    color: '#6a7070',
    fontSize: '14px',
    textAlign: 'center'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  iconStyle: {
    display: 'flex',
    paddingLeft: '12px',
    cursor: 'pointer',
    alignItems: 'center'
  },
  fontStyle: {
    marginLeft: '10px'
  }
});

const Listings = (props) => {
  const {
    classes,
    ChatgptUtilityIESUserData,
    chatgptListingUtility,
    chatgptResponseUtility,
    isUserIdExcluded,
    internalUsers
  } = props;
  const feedback = false;
  const [highLight, setHighLght] = useState(-1);
  const [trendsList, setTrendsList] = useState([]);
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [clusterTable, setClusterTable] = useState([]);
  const [clusterName, setClusterName] = useState('');
  const [showTable, setshowTable] = useState(false);
  const [rowDialogue, setRowDialogue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const [openChat, openChatDialog] = useState(false);
  const [dataLength, setDatalength] = useState({});
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromValue, setFromValue] = useState(0);
  const [filteredData, setFilteredData] = useState(chatgptListingUtility?.data);

  useEffect(() => {
    const combinedObjects = {};
    setshowTable(false);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
      const { userData } = ChatgptUtilityIESUserData;
      if (userData.length > 0) {
        setUserEmailDetails(userData[userData.length - 1]);
      }
    });

    if (filteredData.length > 0) {
      filteredData.forEach((obj) => {
        const key = obj.clusterName && obj.clusterName.split('').sort().join('');
        if (combinedObjects[key]) {
          combinedObjects[key].count += 1;
        } else {
          combinedObjects[key] = { name: obj.clusterName, count: 1 };
        }
      });
      if (Object.values(combinedObjects).length > 0) {
        const pushToLast = Object.values(combinedObjects);
        const filteredItems = pushToLast.filter((item) => item.name !== 'Other topics');
        const itemToMove = pushToLast.find((item) => item.name === 'Other topics');
        const resultArray = filteredItems
          .sort((a, b) => b.count - a.count)
          .map(({ name, count }) => (count > 1 ? `${name} (${count})` : name));
        const updatedItems = !_.isEmpty(itemToMove)
          ? [...resultArray, `${itemToMove.name} (${itemToMove.count})`]
          : resultArray;
        setTrendsList(updatedItems);
      }
    }
    Framework.getEventManager().on(constants.GET_CHATGPT_RESPONSE_MODEL_DATA, () => {
      const storeData = utils.processStoreValue(chatgptResponseUtility);
      if (storeData) {
        setRowDialogue(storeData);
      }
    });
    // setTrendsList(data);
  }, [filteredData]);

  useEffect(() => {
    const internalUserIds = JSON.parse(JSON.stringify(toJS(internalUsers)));
    if (isUserIdExcluded && internalUserIds?.userId.length > 0) {
      const filteredItems = filteredData.filter((item) => !internalUserIds?.userId.includes(item.userId));
      setFilteredData(filteredItems);
    } else {
      setFilteredData(chatgptListingUtility?.data);
    }
  }, [isUserIdExcluded]);

  function getObjectName(array, targetName) {
    const matchObjects = array.filter((obj) => obj.clusterName === targetName);
    return matchObjects.map(({ serverDateTime, userCommand, dateStamp, bookId, userId, title, type, requestId }) => ({
      serverDateTime,
      userCommand,
      dateStamp,
      bookId,
      userId,
      type,
      title,
      requestId
    }));
  }

  const handleList = (item, listIndex) => {
    setHighLght(listIndex);
    ChatgptConfig.chatData.forEach((value) => {
      const alteredValue = value.bookId;
      if (!alteredValue) return;
      filteredData.forEach((dataValue, index) => {
        if (dataValue.bookId === alteredValue) {
          filteredData[index].title = value.title;
        }
      });
    });
    const trimmedName = item.replace(/\(\d+\)/g, '').trim();
    setClusterName(trimmedName);
    setshowTable(true);
    const matchingNamesArray = getObjectName(filteredData, trimmedName);
    const sortedArray = matchingNamesArray.sort((x, y) => new Date(y.dateStamp) - new Date(x.dateStamp));
    setPage(0);
    setClusterTable(sortedArray);
  };

  const handleEvent = (params, index) => {
    const { requestId, bookId } = params;
    setDatalength(clusterTable.length);
    // setRowDialogue(params);
    openChatDialog(true);
    setModalIndex(fromValue + index);
    chatgptResponseUtility.fetch({ type: constants.CHAT_UTILITY_ANSWER, requestId, bookId });
  };

  const handleClose = () => {
    openChatDialog(false);
    setModalIndex(1);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      const { requestId, bookId } = clusterTable[modalIndex - 1];
      // setRowDialogue(clusterTable[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
      chatgptResponseUtility.fetch({ requestId, bookId, type: constants.CHAT_UTILITY_ANSWER });
    } else {
      const { requestId, bookId } = clusterTable[modalIndex + 1];
      // setRowDialogue(clusterTable[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
      chatgptResponseUtility.fetch({ requestId, bookId, type: constants.CHAT_UTILITY_ANSWER });
    }
  };

  const handleAscending = (dir, value) => {
    if (dir === 'asc') {
      const sorted = [...clusterTable].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      setClusterTable(sorted);
    } else {
      const sorted = [...clusterTable].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      setClusterTable(sorted);
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((item) => item.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const LightTooltip = styled(({ className, ...prop }) => <Tooltip {...prop} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
      }
    })
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFromToValueForPagination = (from, to, count) => {
    setFromValue(from - 1);
    return `${from}-${to} of ${count}`;
  };

  return (
    <>
      <Paper>
        <Grid item xs={12}>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
            <br />
            {trendsList.length > 0 && (
              <Grid container direction="row">
                {trendsList.length > 0 && (
                  <Grid item xs={7}>
                    <br />
                    <Typography align="left" variant="h3">
                      {constants.CLUSTER_LIST}
                    </Typography>
                    <br />
                  </Grid>
                )}
                <Grid item xs={5}>
                  <br />
                  <Typography align="left" variant="h3">
                    {showTable && clusterName}
                  </Typography>
                  <br />
                </Grid>
                <Grid item xs={4} className={classes.tableColumnHeight}>
                  {trendsList.length > 0 && (
                    <Box
                      sx={{
                        width: '100%',
                        maxWidth: 360,
                        height: '100%',
                        border: '1px solid #0003',
                        overflowY: 'scroll',
                        borderRadius: '2px'
                      }}
                    >
                      {trendsList.length > 0 &&
                        trendsList.map((item, index) => (
                          <div key={index} className={index === highLight ? classes.highlighted : ''}>
                            <div
                              className={classes.iconStyle}
                              onClick={() => handleList(item, index)}
                              onKeyDown={() => handleList(item)}
                              role="button"
                              tabIndex={0}
                            >
                              <LightbulbIcon sx={{ color: '#0003' }} />
                              <p className={classes.fontStyle}>{item}</p>
                            </div>
                          </div>
                        ))}
                      <Divider />
                    </Box>
                  )}
                </Grid>
                <Grid item xs={8} className={classes.tableLeftWidth}>
                  <Box sx={{ width: '100%' }}>
                    {showTable && (
                      <Paper sx={{ p: 2 }} className={classes.tableLeftScroll}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.GPT_TRENDS_DATE}
                                    <TableSortLabel
                                      onClick={() => sorting('dateStamp')}
                                      active
                                      direction={directionValue === 'dateStamp' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.BOOK_ID}
                                    <TableSortLabel
                                      onClick={() => sorting('bookId')}
                                      active
                                      direction={directionValue === 'bookId' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.GPT_TRENDS_BOOKTITLE}
                                    <TableSortLabel
                                      onClick={() => sorting('')}
                                      active
                                      direction={directionValue === '' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.GPT_TRENDS_USER_INPUT}
                                    <TableSortLabel
                                      onClick={() => sorting('userCommand')}
                                      active
                                      direction={directionValue === 'userCommand' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography variant="h6" className={classes.columnStyle}>
                                    {constants.CHAT_USERLOGIN}
                                    <TableSortLabel
                                      onClick={() => sorting('userId')}
                                      active
                                      direction={directionValue === 'userId' && order === 'DSC' ? 'asc' : 'desc'}
                                      sx={{
                                        // disable cell selection style
                                        '.MuiDataGrid-cell:focus': {
                                          outline: 'none'
                                        },
                                        // pointer cursor on ALL rows
                                        '& .MuiTableSortLabel-root:hover': {
                                          cursor: 'pointer'
                                        }
                                      }}
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {clusterTable
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                  <TableRow onClick={() => handleEvent(row, index)}>
                                    <TableCell>
                                      <Typography className={classes.columnData}>{row.dateStamp}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className={classes.columnData}>{row.bookId}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className={classes.columnData}>{row.title}</Typography>
                                    </TableCell>
                                    <TableCell>
                                      <Typography className={classes.columnData}>{row.userCommand}</Typography>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      onMouseEnter={() => hanldeGETUserId(row.userId)}
                                      key={index}
                                    >
                                      <LightTooltip
                                        title={
                                          userEmailDetails
                                            ? Object.keys(userEmailDetails).map((item) => {
                                                let poperValue = <></>;
                                                if (item === 'firstName') {
                                                  poperValue = (
                                                    <Typography
                                                      gutterBottom
                                                      className={classes.tableFilterTooltextWidth}
                                                    >
                                                      <b>First Name : </b>

                                                      {userEmailDetails[item]}
                                                    </Typography>
                                                  );
                                                } else if (item === 'lastName') {
                                                  poperValue = (
                                                    <Typography gutterBottom>
                                                      <b>Last Name : </b>

                                                      {userEmailDetails[item]}
                                                    </Typography>
                                                  );
                                                } else if (item === 'email') {
                                                  poperValue = (
                                                    <Typography gutterBottom>
                                                      <b>Email : </b>

                                                      {userEmailDetails[item]}
                                                    </Typography>
                                                  );
                                                }
                                                return poperValue;
                                              })
                                            : 'Loading Data ...'
                                        }
                                      >
                                        <Typography className={classes.columnData}>{row.userId}</Typography>{' '}
                                      </LightTooltip>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          component="div"
                          count={clusterTable.length}
                          labelDisplayedRows={({ from, to, count }) =>
                            useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
                          }
                          page={page}
                          onPageChange={handleChangePage}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          style={{ display: 'ruby-text' }}
                        />
                      </Paper>
                    )}
                  </Box>
                </Grid>
                <br />
                <br />
                {openChat && (
                  <ChatDialogComponent
                    openChat={openChat}
                    dataValue={rowDialogue}
                    filterPromt={constants.FILTER_PROMPT}
                    dataLength={dataLength}
                    modalIndex={modalIndex}
                    handleClose={handleClose}
                    handleModalChange={handleModalChange}
                    isTrendsInsightsList
                    isFeedback={feedback}
                  />
                )}
              </Grid>
            )}
          </Box>
        </Grid>
      </Paper>
    </>
  );
};

Listings.propTypes = {
  chatgptUtilityListingData: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  chatgptListingUtility: shapes.modelOf(Listing).isRequired,
  chatgptResponseUtility: shapes.modelOf(ResponseModel).isRequired,
  setListingResponseData: PropTypes.func.isRequired,
  isUserIdExcluded: PropTypes.bool.isRequired,
  internalUsers: shapes.modelOf(InternalUsersList).isRequired
};

export default withStyles(styles)(
  observer(
    inject('ChatgptUtilityIESUserData', 'chatgptListingUtility', 'chatgptResponseUtility', 'internalUsers')(Listings)
  )
);
