import {
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  withStyles,
  Button,
  Checkbox
} from '@material-ui/core';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import TooltipCustom from './TooltipCustom';
import * as constants from '../constants';
import utils from '../utils';

const styles = () => ({
  tableCustomWidth: {
    width: '2px'
  },
  columnStyle: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    fontWeight: '600'
  },
  radioButton: {
    textAlign: 'center'
  },
  root: {
    width: '100%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  },
  columnWidth: {
    width: 200
  },
  restorecolumnWidth: {
    width: 50,
    textAlign: 'center'
  },
  tableFilterTooltextWidth: {
    fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
    color: '#252525'
  },
  effectivenessOutputColumn: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14,
    position: 'absolute',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '22%'
  },
  promptTypeColumn: {
    wordWrap: 'break-word'
  }
});

const TableComponent = (props) => {
  const {
    columns,
    data,
    classes,
    onColumnClick,
    order,
    onRowClick,
    selectedValue = '',
    directionValue,
    userEmailDetails,
    onMouseEnter,
    isFromChannelsTrends = false,
    archived,
    handleArchivedclick,
    disableRepublish,
    handleClone,
    showCloneIcon,
    setUserId,
    userIdChecked,
    isFromCharts
  } = props;
  const LightTooltip = styled(({ className, ...params }) => <Tooltip {...params} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11
      }
    })
  );

  const renderRowsData = (row, column) => {
    const highlightRow = () => {
      let styleVal = { bgColor: 'transparent', textColor: '#6a7070' };
      const rowData = ['Answer Relevance', 'Context Relevance', 'Correctness', 'Groundedness'].includes(column.key);
      if (rowData && Array.isArray(row.feedbacks)) {
        row.feedbacks.forEach((item) => {
          if (item[column.key] !== null) {
            if (item[column.key] > 0.5 && item[column.key] < 0.9) {
              styleVal = { bgColor: '#e9e92a', textColor: 'black' };
            }
            if (item[column.key] >= 0.9) {
              styleVal = { bgColor: '#5fb65f', textColor: 'black' };
            }
            if (item[column.key] <= 0.5) {
              styleVal = { bgColor: '#e64242', textColor: 'black' };
            }
          }
        });
      }
      return styleVal;
    };

    const renderSubRowsData = (rows, colVals) => {
      if (Array.isArray(rows[colVals.key])) {
        if (colVals.key === constants.PROMPT_START_COLUMN_NAME || colVals.key === constants.PROMPT_END_COLUMN_NAME) {
          return (
            <>
              {/* eslint-disable-next-line */}
              <Typography
                className={classes.effectivenessOutputColumn}
                style={{ width: '8%', height: '20px' }}
                dangerouslySetInnerHTML={{ __html: rows[colVals.key][0] && rows[colVals.key][0].content }}
              />
            </>
          );
        }
        return (
          /* eslint-disable react/no-danger */
          <div
            className={classes.effectivenessOutputColumn}
            style={{ width: '10%' }}
            dangerouslySetInnerHTML={{ __html: rows[colVals.key][0] && rows[colVals.key][0].content }}
          />
          /* eslint-disable react/no-danger */
        );
      }
      if (colVals.key === 'output') {
        return rows[colVals.key].replace(/<\/?[^>]+(>|$)/g, ' ');
      }
      if ((showCloneIcon || archived) && colVals.key === 'type') {
        return (
          <>
            {/* eslint-disable-next-line */}
            <div
              style={{ width: column.columnWidth }}
              className={classes.promptTypeColumn}
              dangerouslySetInnerHTML={{ __html: rows[colVals.key] }}
            />
          </>
        );
      }
      return rows[colVals.key];
    };

    return (
      <TableCell style={{ width: column.columnWidth, backgroundColor: highlightRow().bgColor }}>
        <Typography
          className={column.key === 'output' ? classes.effectivenessOutputColumn : classes.tableText}
          style={{ color: highlightRow().textColor }}
        >
          {row[column.key] === undefined && Array.isArray(row.feedbacks)
            ? row.feedbacks.filter((item) => item[column.key] !== null).map((item) => item[column.key])
            : renderSubRowsData(row, column)}
        </Typography>
      </TableCell>
    );
  };

  const handleUserIdCheck = (item, checkedData) => {
    setUserId(item, checkedData);
  };

  return (
    <TableContainer className={classes.root} component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {!isFromChannelsTrends && (
              <TableCell align="center" className={classes.tableCustomWidth}>
                <Typography variant="h6" className={classes.columnStyle}>
                  {constants.SELECT}
                </Typography>
              </TableCell>
            )}
            {columns.map((column) => (
              <>
                <TableCell align="center" sortDirection="asc" style={{ width: column.columnWidth }}>
                  <Typography variant="h6" className={classes.columnStyle}>
                    {column.name}
                    <TableSortLabel
                      onClick={() => onColumnClick(column.key, archived)}
                      active
                      direction={directionValue === column.key && order === 'DSC' ? 'asc' : 'desc'}
                      sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                          outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiTableSortLabel-root:hover': {
                          cursor: 'pointer'
                        }
                      }}
                    />
                  </Typography>
                </TableCell>
              </>
            ))}
            {archived && (
              <TableCell align="center" className={classes.tableCustomWidth}>
                <Typography variant="h6" className={classes.columnStyle}>
                  Restore
                </Typography>
              </TableCell>
            )}
            {showCloneIcon && (
              <TableCell align="center" className={classes.tableCustomWidth}>
                <Typography variant="h6" className={classes.columnStyle}>
                  Clone
                </Typography>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <>
              <TableRow onClick={() => onRowClick(row, index)}>
                {!isFromChannelsTrends && (
                  <TableCell className={classes.radioButton}>
                    <Radio size="small" checked={selectedValue === index} color="primary" name="radioButton" />
                  </TableCell>
                )}
                {columns.map((column) => (
                  <>
                    {column.key === 'userId' ? (
                      <TableCell
                        className={classes.tableFilterTooltipWidth}
                        onMouseEnter={() => onMouseEnter(row[column.key])}
                        key={index}
                      >
                        <LightTooltip
                          title={
                            userEmailDetails
                              ? Object.keys(userEmailDetails).map((item) => {
                                  let poperValue = <></>;
                                  if (item === 'firstName') {
                                    poperValue = (
                                      <Typography gutterBottom className={classes.tableFilterTooltextWidth}>
                                        <b>First Name : </b>
                                        {userEmailDetails[item]}
                                      </Typography>
                                    );
                                  } else if (item === 'lastName') {
                                    poperValue = (
                                      <Typography gutterBottom>
                                        <b>Last Name : </b>
                                        {userEmailDetails[item]}
                                      </Typography>
                                    );
                                  } else if (item === 'email') {
                                    poperValue = (
                                      <Typography gutterBottom>
                                        <b>Email : </b>
                                        {userEmailDetails[item]}
                                      </Typography>
                                    );
                                  } else if (!isFromCharts && item === 'userId') {
                                    poperValue = (
                                      <Typography gutterBottom>
                                        <b>Filter results by this User Id : </b>
                                        <Checkbox
                                          defaultChecked
                                          checked={userIdChecked}
                                          onChange={() => handleUserIdCheck(item, row.userId)}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                          color="default"
                                          sx={{
                                            color: 'blue',
                                            '&.Mui-checked': {
                                              color: 'blue'
                                            }
                                          }}
                                        />
                                        {/* {userEmailDetails[item]} */}
                                      </Typography>
                                    );
                                  }
                                  return poperValue;
                                })
                              : 'Loading Data ...'
                          }
                        >
                          <Typography className={classes.tableText}>{utils.displayUserId(row)}</Typography>
                        </LightTooltip>
                      </TableCell>
                    ) : (
                      renderRowsData(row, column)
                    )}
                  </>
                ))}
                {archived && (
                  <TableCell className={classes.restorecolumnWidth} key={index}>
                    <Button onClick={(event) => handleArchivedclick(event, row)} disabled={disableRepublish}>
                      <TooltipCustom title="Restore">
                        <RestartAltIcon />
                      </TooltipCustom>
                    </Button>
                  </TableCell>
                )}
                {showCloneIcon && (
                  <TableCell className={classes.restorecolumnWidth} key={index}>
                    <Button onClick={(event) => handleClone(event, row)} disabled={disableRepublish}>
                      <TooltipCustom title="Clone">
                        <ContentCopyIcon />
                      </TooltipCustom>
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  onColumnClick: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  directionValue: PropTypes.string.isRequired,
  userEmailDetails: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  isFromChannelsTrends: PropTypes.bool.isRequired,
  archived: PropTypes.bool,
  disableRepublish: PropTypes.bool,
  handleArchivedclick: PropTypes.func,
  handleClone: PropTypes.func,
  showCloneIcon: PropTypes.bool,
  setUserId: PropTypes.func.isRequired,
  userIdChecked: PropTypes.bool.isRequired,
  isFromCharts: PropTypes.bool
};

TableComponent.defaultProps = {
  archived: false,
  disableRepublish: false,
  handleArchivedclick: () => {},
  handleClone: () => {},
  showCloneIcon: false,
  isFromCharts: false
};

export default withStyles(styles)(TableComponent);
