import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const Version = types.model('Version', {
  version3: types.maybeNull(types.boolean)
});

const MathJaxSwitch = types
  .model('MathJaxSwitch', {
    mathJaxVersion: types.optional(Version, {})
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_FILE_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self.mathJaxVersion, response.data.mathJaxVersion);
    }
  }));

export default MathJaxSwitch;
