import { types, applySnapshot } from 'mobx-state-tree';
import Framework from '@greenville/framework';
import * as constants from '../../../common/constants';

const InternalUsersList = types
  .model('InternalUsersList', {
    userId: types.optional(types.array(types.string), [])
  })
  .actions((self) => ({
    fetch(payload) {
      Framework.getEventManager().publish(constants.GET_INTERNAL_USERS_LIST_DATA, {
        payload
      });
    },
    setResponse(response) {
      applySnapshot(self, response);
      Framework.getEventManager().publish(constants.SET_INTERNAL_USERS_LIST_DATA);
    },
    resetStoreValues() {
      const initialState = [];
      applySnapshot(self.data, initialState);
    }
  }));

export default InternalUsersList;
